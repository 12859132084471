import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Header from "../components/header/Header"
import Navbar from "../components/navbar/navbar"
import Footer from "../components/footer/footer"

import "./index.scss"
import "./gallery.scss"

const Gallery = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulGallery {
        images {
          file {
            url
          }
          title
        }
        title
      }
      contentfulGalleryMax {
        imagebundle1 {
          file {
            url
          }
          title
        }
        imagebundle2 {
          file {
            url
          }
          title
        }
      }
    }
  `)

  return (
    <div>
      <Header />
      <Navbar />
      <div />
      <h2 className="page-title">{data.contentfulGallery.title}</h2>
      {data.contentfulGallery.images.map(image => {
        return (
          <div className="gallery-min">
            <img
              className="grid-container-image"
              src={image.file.url}
              key={image.title}
              alt={image.title}
            />
          </div>
        )
      })}
      <div className="gallery-max">
        <img
          className="grid-container-image"
          src={data.contentfulGalleryMax.imagebundle1.file.url}
          alt={data.contentfulGalleryMax.imagebundle1.title}
        />
      </div>
      <div className="text">And many more to be made in the years to come!</div>
      <Footer />
    </div>
  )
}

export default Gallery
